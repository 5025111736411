import S from 's-js';
import * as Surplus from 'surplus';
import { promiseSData } from '../util';

export function Loader(
	{ initialized, placeholder = "Loading...", children } : 
		{ initialized: () => boolean, placeholder?: JSX.Children, children: JSX.Children }
) {
	const display = S(() => initialized() ? children : placeholder, placeholder);
	return (() => display()) as undefined as JSX.Element;
}

export function Loading<T>({ task, placeholder = "Loading ...", children }: { task: () => Promise<T>, placeholder?: JSX.Children, children: (data: T) => JSX.Children }) {
	const data = S.value(null as null | T);
	S(() => { data(null); task().then(data); });
	return () => data() ? children(data()!) : placeholder;
}

export function PromiseLoader(
	{ p } : { p: Promise<JSX.Children> }
) {
	const value = promiseSData(p, null);
	return (() => value()) as undefined as JSX.Element;
}