import S from "s-js";
import * as Surplus from "surplus";
import { Cart } from "../..//app/main/cart";
import data from "surplus-mixin-data";
import { HashSignal } from "src/app/hashSignal";

export const CartQty = (
	{cart, location, id, stock, checkOut}
	:{cart: Cart, location: HashSignal, id: string, stock: number, checkOut?: true}
) => {
	return (
		cart.qty(id)()
		? <div class='cart-qty'>
			<button class='plus-minus' onClick={() => cart.qty(id)(cart.qty(id)() - 1)}>-</button>
			<input class='qty' type='number' fn={data(cart.qty(id))} />
			<button class='plus-minus' onClick={() => cart.qty(id)(cart.qty(id)() + 1)}>+</button>
			<br />
			<button class='w5 small clear' onClick={() => cart.remove(id)}>Remove</button>
			<br />
			{
				checkOut
				? <button class='w5' onClick={() => location('/#/check-out')}>Check Out</button>
				: null
			}
		</div>
		: <div class='cart-qty'><button class='w5' onClick={() => cart.qty(id)(1)}>Add to cart</button></div>
	)
}