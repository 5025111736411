import S, {DataSignal} from "s-js";
import { Client } from "./client";

const CART_KEY = 'cart';

type CartItems = Record<string, DataSignal<number>>; // product id -> count

export type Cart = ReturnType<typeof getCart>;
export const getCart = (client: ReturnType<typeof Client>) => {
	const
		cart: DataSignal<CartItems> = S.data({}),
		count = S(() => Object.values(cart()).reduce((c, n)=>c+n(), 0), 0),
		qty = function(id: string) {
			return (n?: number) => {
				const itm = cart()[id];
				if(n!==undefined) {
					if(n <= 0) {
						remove(id);
						return 0;
					} else {
						if(n > 8) n = 8;
						client.product(id).then(p=>{
							if (n > p.Stock) n = p.Stock;
							if (!itm) {
								const temp = cart();
								temp[id] = S.data(n);
								cart(temp);
							} else cart()[id](n);
						});
						return n;
					}
				}
				return itm ? itm() : 0;
			}
		},
		remove = (id: string) => {
			const temp = cart();
			delete(temp[id]);
			cart(temp);
		};
	const temp = (JSON.parse(localStorage.getItem(CART_KEY) || '{}')) as Record<string, number>;
	for (let id in temp) {
		qty(id)(temp[id]);
	}
	S(() => localStorage.setItem(CART_KEY, toJSON()), null);
	
	return { count, cart, qty, remove, toMapSample };

	function toMapSample(): Record<string, number>{
		const cartItems: Record<string, number> = {};
		const tempItms = S.sample(cart);
		for (let id in tempItms) {
			cartItems[id] = S.sample(tempItms[id]);
		}
		return cartItems;
	}

	function toJSON(): string {
		return JSON.stringify(
			Object.keys(cart()).reduce((obj, id) => {
				obj[id] = cart()[id]();
				return obj;
			}, {})
		);
	}
};