import S from 's-js';
import * as Surplus from "surplus";
import SArray from 's-array';
import firebase from 'firebase/app';

import PLACEHOLDER from "../../img/placeholder.jpg";

import { App } from "../../app/main/app";

import { Page, MenuSection } from "./Page";
import { Loader, PromiseLoader } from '../Loader';
import { Product, price } from '../../app/model';
import { CartQty } from './CartQty';
import { DumbThumbnail } from '../Thumbnail';

export { ProductRoute };

type ProductModel = ReturnType<typeof ProductModel>;
type ProductRouteOpts = { id: string };
const
	ProductRoute = async (app: App, opts: ProductRouteOpts) => {
		const model = ProductModel(app, opts);
		return () => <ProductPage {...model} />
	},
	ProductModel = (app: App, { id }: ProductRouteOpts) => { // initialize with loader somehow?
		const
			product = S.data(null as Product),
			initialized = S(() => !!product());
		S(() => {
			app.client.product(id).then(product)
		});
		return { app, product, initialized };
	},
	ProductPage = (model: ProductModel) => (
		<Page app={model.app} title={model.product()?.Name} section={MenuSection.Store}>
			<Loader initialized={model.initialized}>{(() => <ProductContent {...model} />) as any as JSX.Element}</Loader>
		</Page>
	),
	ProductContent = ({ app: { fire, cart, client, location }, ...m }: ProductModel) => (
		<div>
			<h3><a href='#/store'>Store</a> ~ <PromiseLoader p={client.category(m.product().Category).then(c => <a href={`#/store/category/${c.id}`}>{c.Name}</a>)} /></h3>
			<h1 class='mb'>
				<div class='g w1 center'>&#8728; &nbsp; &#8728; &nbsp; &#8728;</div><div class='g w4 mt'>{m.product().Name}</div>
			</h1>
			<div class='vcenter'>
				<div class='g w3 center'>
					<ProductDisplay fire={fire} id={m.product().id} />
				</div>
				<div class='g w2 center'>
					<div class='g w1-2 center mr'>
						{
							m.product().Stock >= 3
							? <h2 class='in-stock'>In stock</h2>
							: m.product().Stock
							? <h2 class='low-stock'>Stock: {m.product().Stock}</h2>
							: !m.product().Discontinued
							? [<h2 class='no-stock'>Out of stock</h2>, <h5>Check back soon!</h5>]
							: <h2 class='discontinued'>discontinued</h2>
						}
						{
							!m.product().Stock 
							? null
							: <div>{price(m.product().Price)} each</div>
						}
					</div>
					<div class='g w1-2 pad'>
						{
							!m.product().Stock 
							? null
							: <CartQty cart={cart} location={location} stock={m.product().Stock} id={m.product().id} checkOut />
						}
					</div>
				</div>
			</div>
			<div class='g w3 m1 mt'>
				<h3 class='mt'>About</h3>
				{m.product().Description}
			</div>
			<div class='g w3 mt'>
				{
					m.product().Ingredients
						? [<h3>Ingredients</h3>, m.product().Ingredients]
						: null
				}
			</div>
			<div class='g w2 tags mt'>
				Tags: &nbsp;
				<button class='tag small' onClick={()=>location(`/#/store/category/${m.product().Category}`)}>
					<PromiseLoader p={client.category(m.product().Category).then(c=>c.Name)} />
				</button>
				{m.product().Tags.map(t=> <button class='tag small' onClick={()=>location(`/#/store/tags/${t}`)}>
					<PromiseLoader p={client.tag(t).then(t=>t.Name)} />
				</button>)}
			</div>
		</div>),
		ProductDisplay = ({fire, id}: {fire: firebase.app.App, id:string}) => {
			const
				current = S.data<string>(PLACEHOLDER),
				srcs = SArray<string>([]);
			function initUrls(i: number) {
				if (i > 3) return;
				fire.storage().ref(`products/${id}/${i.toString()}_700x700.jpg`).getDownloadURL()
					.then(url => {
						if(current()==PLACEHOLDER) current(url);
						srcs.push(url);
					}, () => {})
					.finally(() => {
						initUrls(i + 1);
					});
			}
			initUrls(0);

			return <div>
				<img class='w5 round' style-maxWidth='50vw' src={current()} />
				{
					srcs().length === 1 ? null :
					<div class='round-ends hide-small'>
							{srcs().map((src, i) => <div class='g w1-3 click' onClick={() => {
								current(srcs()[i]);
							}}>
							<DumbThumbnail src={src} />
						</div>)}
					</div>
				}
			</div>;
		};
	;