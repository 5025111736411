import S, {DataSignal} from 's-js';
import firebase from 'firebase/app';
import { SPromise } from '../util';

/**
 * AuthMonitor - monitor requests for auth failures and requests a re-login when detected
 * 
 * Creates two functions:
 * - a wrapper function, requireAuth, which runs the wrapped function and watches
 *   to see if it triggers an authentication error.
 * - a signal, requireCredentials, which tells the app that an authentication error
 *   has occurred and the app should request new credentials from the user.
 */
export type AuthMonitor = ReturnType<typeof AuthMonitor>;
export const AuthMonitor = <T>(
	user: DataSignal<null | T>,
	makeUser: (u: firebase.User) => Promise<T>,
	isLoggedIn: () => boolean,
	isAuthFailure: (e: any) => boolean,
) => {
	const requestLogin = S.data(null as null | {allowGuest?: true});
	firebase.auth().onIdTokenChanged(((u) => {
		if (u) makeUser(u).then(user);
		else user(null);
	}));

	const watchLogin = () => new SPromise(user, 0, u=>u!==null);
	const logOut = (() => { return firebase.auth().signOut().then(() => user(null)); });

	return { requestLogin, requireAuth, logOut };

    /**
     * requireAuth() takes a function and returns a wrapped function with an identical call signature.
     * The wrapped function detects auth failures -- rejected Promises whose rejection value matches 
     * isAuthFailure -- and calls for a login when it detects a potential or actual auth failure.
     * 
     * @param fn - the function to wrap
     */
	function requireAuth<T extends (...args: any[]) => Promise<any>>(fn: T): T {
		return <T>(async function (...args) {
			// if user hasn't authenticated yet, we need to do so before the first request
			if (isLoggedIn()) {
				try {
					return await fn(...args);
				} catch (e) {
					// if request fails with an auth failure, re-authenticate() and try again.
					if (isAuthFailure(e)) {
						S.freeze(() => { user(null); requestLogin({}); });
						await watchLogin();
						// if we get a second auth failure we pass it through
						return await fn(...args);
					} else {
						user(null);
						throw e;
					}
				}
			} else {
				requestLogin({});
				await watchLogin();
				return await fn(...args);
			}
		});
	}
}