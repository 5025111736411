import S from "s-js";
import * as Surplus from "surplus";
import firebase from "firebase/app";

import PLACEHOLDER from "../img/placeholder.jpg";
import { Cache, M } from "src/util";

const urlCache: M<string> = {};

export const Thumbnail = ({ fire, id }: { fire: firebase.app.App, id: string }) => {
	const src = S.value<string>(null);
	S(() => {
		if (id in urlCache) src(urlCache[id]);
		else fire.storage().ref(`products/${id}/0_200x200.jpg`).getDownloadURL()
			.catch(() => fire.storage().ref(`products/${id}/1_200x200.jpg`).getDownloadURL())
			.catch(() => fire.storage().ref(`products/${id}/2_200x200.jpg`).getDownloadURL())
			.catch(() => fire.storage().ref(`products/${id}/3_200x200.jpg`).getDownloadURL())
			.catch(() => PLACEHOLDER)
			.then(url => {
				urlCache[id] = url;
				src(url);
			});
	});

	return <div class='thumbnail round' style-maxWidth="180px" style-backgroundImage={`url('${src()}'), url('${PLACEHOLDER}')`}></div>;
};

export const DumbThumbnail = ({ src }: { src: string }) => {
	return <div class='thumbnail' style-backgroundSize='cover' style-backgroundImage={`url('${src}'), url('${PLACEHOLDER}')`}></div>;
};