


export interface Configuration {
	fire: {  // passed as-is to SDK
		apiKey: string;
		projectId: string;
		authDomain: string;
		clientId: string;
		databaseURL: string;
		storageBucket: string;
		functionsURL: string;
	};
	stripe: string;
}

export const PublicCFG: Configuration = {
	fire: {
		apiKey: "AIzaSyCRk3rGrzDu-4LtwynAsE4g99o5yEqRuR8",
		projectId: "sunburstridgefarm",
		authDomain: "sunburstridgefarm.com",
		clientId: `1074577578553-1uvs5guekhrnhq9mhut9nqp1q0cd59bg.apps.googleusercontent.com`,
		databaseURL: "https://sunburstridgefarm.firebaseio.com",
		storageBucket: "sunburstridgefarm.appspot.com",
		functionsURL: `https://us-central1-sunburstridgefarm.cloudfunctions.net/`,
	},
	stripe: 'pk_live_FoouTl8PGwWzW89KxrSQAZNa00DczPVgXJ'
	// stripe: 'pk_test_ZH5aBRj63Vm4G0sWkYEOHpT5005MBHbvtX'
};

export const AdminCFG: Configuration = {
	fire: {
		apiKey: "AIzaSyCRk3rGrzDu-4LtwynAsE4g99o5yEqRuR8",
		projectId: "sunburstridgefarm",
		authDomain: "srf-admin.firebaseapp.com",
		clientId: `1074577578553-1uvs5guekhrnhq9mhut9nqp1q0cd59bg.apps.googleusercontent.com`,
		databaseURL: "https://sunburstridgefarm.firebaseio.com",
		storageBucket: "sunburstridgefarm.appspot.com",
		functionsURL: `https://us-central1-sunburstridgefarm.cloudfunctions.net/`,
	},
	stripe: 'pk_live_FoouTl8PGwWzW89KxrSQAZNa00DczPVgXJ'
	// stripe: 'pk_test_ZH5aBRj63Vm4G0sWkYEOHpT5005MBHbvtX'
};