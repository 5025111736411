import * as Surplus from "surplus";

import LOGO_SVG from "../img/logo.svg";
import LOGO_PNG from "../img/logo.png";
import LOGO_BW_SVG from "../img/logo-b+w.svg";
import LOGO_BW_PNG from "../img/logo-b+w.png";

export const Logo = ({width, blackAndWhite}: {width: number, blackAndWhite?: boolean}) =>{
	let ref: HTMLImageElement;
	return <img
		src={blackAndWhite ? LOGO_BW_SVG : LOGO_SVG}
		{...{width}}
		ref={ref}
		alt='Sunburst Ridge Farm logo'
		onError={() => { ref.src = null; ref.src = blackAndWhite ? LOGO_BW_PNG : LOGO_PNG; } } // todo - test - try invalid file
	/>;
}