import firebase from 'firebase/app';

import { M, Cache, cachedOr, Lazy } from '../../util';
import { AuthMonitor } from '../authMonitor';

import { StoreState, User, Product, Event, BrickAndMortar, DBProduct, toProduct, Category, Tag, IDd, matchAny, toMap } from '../model';

// rename ResourceClient or other?
export const Client = (fire: firebase.app.App, user: () => User, { requireAuth: auth }: AuthMonitor) => {
	const db = fire.firestore();
	const one = oneFrom(db);
	const many = manyFrom(db);
	
	const
		products = new Cache<M<Product>>(10, () =>
			many<DBProduct>('products')
			.then(ps => ps.map(p => toProduct(p)))
			.then(toMap)),
		categories = new Cache<M<Category>>(30, () => many<Category>('categories').then(toMap)),
		tags = new Cache<M<Tag>>(30, ()=>many<Tag>('tags').then(toMap)),
		events = new Cache<Array<Event>>(30, ()=>
			many<Event>('events', [['Date', '>', new Date(Date.now() - 864e5)]]).then(l=>l.sort((a, b)=> a.Date < b.Date ? -1 : 1)) // todo verify as?
		),
		brickAndMortar = new Cache<Array<BrickAndMortar>>(30, ()=>
			many<BrickAndMortar>('brick-and-mortar').then(l=>l.sort((a, b)=> a.Ordering < b.Ordering ? -1 : 1)) // todo verify as?
		),
		storeState = new Cache<StoreState>(40, () => one<StoreState>('/store/state'))
	;

	return {
		products: async (category?: string, tags?: string[]) => {
			let ps: M<Product> = await products.get();
			if(!!category) ps = Object.values(ps).reduce((ps, p) => {
				if(p.Category === category) ps[p.id] = p;
				return ps;
			}, {});
			if (!!tags && !!tags.length) ps = Object.values(ps).reduce((ps, p) => {
				if (matchAny(p.Tags, tags)) ps[p.id] = p;
				return ps;
			}, {});
			return ps;
		},
		product: (id: string) => cachedOr(products, id, ()=>one<DBProduct>(`/products/${id}`).then(toProduct)),
		categories: () => categories.get(),
		tags: () => tags.get(),
		tag: (tag: string) => tags.get().then(ts => ts[tag]),
		category: (cat: string) => categories.get().then(cs => cs[cat]),
		events: () => events.get(),
		brickAndMortar: () => brickAndMortar.get(),
		storeState: () => storeState.get(),
	}
};

/**
 * Does the given rejection value represent an authentication failure?
 * 
 * Used to determine whether to attempt to (re)login and re-try request.
 */
export const isAuthFailure = (e: any) =>
	e.code && ((e as firebase.firestore.FirestoreError).code in ['permission-denied', 'unauthenticated'] || (e.code as string).startsWith('auth'))
;

export function oneFrom(db: firebase.firestore.Firestore) {
	return async <T extends IDd>(path: string): Promise<T> => {
		const d = await db.doc(path).get();
		const t = d.data() as any as T | undefined;
		if (t === undefined) throw new Error("id not found");
		t.id = d.id;
		return t;
	}
}

export function manyFrom(db: firebase.firestore.Firestore) {
	return async <T extends IDd>(path: string, wheres?: Parameters<typeof firebase.firestore.CollectionReference.prototype.where>[]): Promise<T[]> => {
		let q = db.collection(path);
		if (wheres) for (let where of wheres) q.where(...where);
		const a = (await q.get()).docs
			.map((doc) => {
				let t = doc.data() as T;
				t.id = doc.id;
				return t;
			}, {});
		return a;
	}
}
