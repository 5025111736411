import S, { DataSignal } from "s-js";
import { Cart } from "./cart";
import { FunctionsClient, isFatal, ShippingOptions, OrderResponse, OrderResponseItem, ShippingOption } from "./functionsClient";
import { User } from "../model";


export enum Stage {
	CollectEmail,
	CollectShippingAddress,
	CollectShippingOption,
	BillingInfoAndConfirmation,
	Completed,
}

export type Checkout = ReturnType<typeof getCheckout>;

export const getCheckout = (fns: FunctionsClient, stripeKey: string, cart: Cart, user: ()=>User) => {
	const
		stripe = Stripe(stripeKey),
		elements = stripe.elements(),
		cardElement = elements.create('card', {hidePostalCode: true}),
		mountCard = cardElement.mount,

		error = S.value<string|null>(null),
		// email must use account's email if logged in
		email = S.value(S.sample(user) !== null ? S.sample(user).email || null : null),
		shippingAddress = S.value<StripeAddress|null>(null),
		localPickup = S.data(false),
		shippingOptions = S.value<ShippingOption[]|null>(null),
		shippingChoice = S.value<string|null>(null),
		coupon = S.data<string|null>(null),
		// billingInfo = S.value(null),
		items = S.value<OrderResponseItem[]|null>(null),
		amount = S.value<number|null>(null),
		savedPayment = S.data<{brand:string, last4:string}|null>(null),

		// stage = S.value(S.sample(email) === null ? Stage.CollectEmail : Stage.CollectShippingAddress),
		stage = S.value(Stage.CollectEmail),

		handleFatal = (err)=>{
			startOver();
			error("An error has occurred. Please try again.");
			console.error("handleFatal:");
			console.error(err);
			throw err;
		},

		preCheckoutAPI = async (guestEmail?: string) => {
			localPickup(false);
			if(guestEmail === '') {
				error('Please enter a valid email address.');
				return;
			} else if(guestEmail) {
				error(null);
				email(guestEmail);
				stage(Stage.CollectShippingAddress);
				return;
			} else {
				return fns.preCheckout()
				.then((svd?) => {
					error(null);
					if(svd && svd.StripeShipping) {
						shippingAddress({
							name: svd.StripeShipping?.name || '',
							address: {
								line1: svd.StripeShipping?.address?.line1 || '',
								line2: svd.StripeShipping?.address?.line2 || '',
								city: svd.StripeShipping?.address?.city || '',
								state: svd.StripeShipping?.address?.state || '',
								postal_code: svd.StripeShipping?.address?.postal_code || '',
								country: 'US',
							}
						});
					}
					if(svd && svd.CardBrand && svd.CardLast4) {
						savedPayment({brand:svd.CardBrand, last4:svd.CardLast4});
					}
					stage(Stage.CollectShippingAddress);
				}).catch(handleFatal);
			}
		},

		handleFinalizedOrderResponse = (result: OrderResponse) => {
			if (result.Error) {
				error(result.Error.Message);
				return;
			}
			error(null);
			if (!result.Finalized)
				throw "Finalize API call didn't finalize order but didn't give an error";
			items(result.Items);
			amount(result.Amount);
			stage(Stage.BillingInfoAndConfirmation);
		},
		
		startCheckoutAPI = async () =>
			fns.startCheckout(S.sample(shippingAddress), S.sample(localPickup), S.sample(email))
			.then(result => {
				error(null);
				if((result as ShippingOptions).ShippingSelected) {
					const opts = (result as ShippingOptions).ShippingOptions;
					shippingOptions(opts);
					shippingChoice(
						opts.reduce((prev, cur) => cur.amount < prev.amount ? cur : prev, opts[0]).id
					);
					stage(Stage.CollectShippingOption);
				} else if((result as OrderResponse).Error) {
					error((result as OrderResponse).Error.Message);
				} else if((result as OrderResponse).Finalized) {
					handleFinalizedOrderResponse(result as OrderResponse);
				} else {
					throw 'server responded to StartCheckout with OrderResponse where Finalized is false and there is no error';
				}
			})
			.catch(() => {
				error("A valid shipping address is required (even for local pick-ups and drop-offs).");
			})
			// .catch(handleFatal)
		,

		finalizeCheckoutAPI = async () =>
			fns.finalizeCheckout(S.sample(email), S.sample(shippingChoice))
			.then(handleFinalizedOrderResponse)
			.catch(handleFatal)
		,

		clear = () => { // todo double check
			error(null);
			coupon(null);
			shippingOptions(null);
			shippingChoice(null);
			shippingAddress(null);
			email(S.sample(user) !== null ? S.sample(user).email : null);
		},
		completed = (resp: OrderResponse) => {
			S.freeze(() => {
				clear();
				cart.cart({});
				items(resp.Items);
				amount(resp.Amount);
				stage(Stage.Completed);
			});
		},

		confirmAPI = async (
			useSavedPayment: boolean,
			savePayment: boolean,
			billingInfo: Required<Omit<stripe.TokenOptions, 'currency'>>
		) => (
			useSavedPayment ?
				fns.confirmCheckout(S.sample(email), useSavedPayment)
			:
				stripe.createToken(cardElement, billingInfo)
					.then(resp => {
						if(resp.error) {
							error(resp.error.message);
						} else {
							return fns.confirmCheckout(S.sample(email), useSavedPayment, resp.token.id, savePayment)
						}
					})
		).then(resp => {
			if (!resp) return;
			if (!resp.Error && (!resp.Finalized || !resp.Paid))
				throw 'Server didn\'t throw error when it should have';
			if (resp.Error) {
				error(resp.Error.Message);
				return;
			} else {
				completed(resp);
			}
		}).catch(handleFatal),
		
		startOver = ()=>{
			S.freeze(()=>{
				clear();
				stage(Stage.CollectEmail);
			});
		}
	;

	cardElement.on('change', (ev)=>{
		if(ev.error) error(ev.error.message);
	});
	
	// S(()=>{
	// 	// cart has item(s) and stage is completed.. startOver()
	// 	if(cart.count() > 0 && stage() === Stage.Completed) {
	// 		startOver();
	// 	}
	// });
		

	return {
		elements,
		mountCard,
		error,
		email,
		shippingAddress,
		localPickup,
		shippingOptions: shippingOptions as ()=>ShippingOption[], shippingChoice, coupon,
		savedPayment: savedPayment as () => {brand: string; last4: string;},
		items: items as () => OrderResponseItem[],
		amount: amount as () => number,
		stage: stage as ()=>Stage,
		preCheckoutAPI, startCheckoutAPI, finalizeCheckoutAPI, confirmAPI,
		startOver,
	};
}


export interface StripeAddress {
	name: string,
	address: {
		city: string,
		country: 'US',
		line1: string,
		line2: string,
		postal_code: string,
		state: string,
	},
}