import S from 's-js';
import * as Surplus from "surplus";
import data from 'surplus-mixin-data';

import { App } from "../../app/main/app";

import { Page, CheckoutMenuSection } from "./Page";
import { Stage, StripeAddress } from '../../app/main/checkout';
import { Login } from '../Login';

export { CheckoutRoute };


type CheckoutModel = ReturnType<typeof CheckoutModel>;
const
	CheckoutRoute = async (app: App) => {
		const model = CheckoutModel(app);
		return () => <CheckoutPage {...model} />
	},
	CheckoutModel = (app: App) => {
		const
			{ cart: { cart, count, qty, remove }, client, checkout } = app,
			menuSection = S.on(app.checkout.stage, () => {
				const s = app.checkout.stage();
				return s === Stage.CollectEmail ? CheckoutMenuSection.LogIn
				: s === Stage.CollectShippingAddress ? CheckoutMenuSection.Shipping
				: s === Stage.CollectShippingOption ? CheckoutMenuSection.Shipping
				: s === Stage.BillingInfoAndConfirmation ? CheckoutMenuSection.Billing
				: s === Stage.Completed ? CheckoutMenuSection.Completion
				: CheckoutMenuSection.Cart
			}),
			working = S.value(false),
			wrapWorking = (fn: () => Promise<void>) => () => {
				S.freeze(()=>working(true));
				scrollTo(0, 0);
				fn().finally(()=>S.freeze(()=>working(false)));
			}
		;
		return { menuSection, app, working, wrapWorking };
	},
	CheckoutPage = (model: CheckoutModel) => (
		<Page app={model.app} title="Checkout" section={model.menuSection()}>
			<Checkout {...model} />
		</Page>
	),
	Checkout = (m: CheckoutModel) => (
		<div class='g m1 w3 mt'>
			{
				m.working()
				? <div class='w5 info mb'>processing...</div>
				: m.app.checkout.error()
				? <div class='w5 error mb'>{m.app.checkout.error()}</div>
				: null
			}
			<div class={m.working()?'hidden':''}>
				{
					m.app.checkout.stage() === Stage.CollectEmail
					? <Email {...m} />
					: m.app.checkout.stage() === Stage.CollectShippingAddress
					? <Shipping {...m} />
					: m.app.checkout.stage() === Stage.CollectShippingOption
					? <ShippingOption {...m} />
					: m.app.checkout.stage() === Stage.BillingInfoAndConfirmation
					? <Billing {...m} />
					: m.app.checkout.stage() === Stage.Completed
					? <Completed {...m} />
					: null
				}
			</div>

			{/* <div class='stripe-card' fn={m.app.checkout.mountCard}></div>
			<label><input type='checkbox' /> My billing address is the same as my shipping address.</label>

			<label class='w5'>Billing address:</label>
			<input placeholder='Name' class='w5' type='text' />
			<input placeholder='Line 1' class='w5' type='text' />
			<input placeholder='Line 2' class='w5' type='text' />
			<input placeholder='City' class='city w3' type='text' />
			<input placeholder='State' class='state w1' type='text' />
			<input placeholder='ZIP' class='zip w1' type='text' /> */}
		</div>
	),
	Shipping = (m: CheckoutModel) => {
		const
			address: AddressInputs = {},
			next = m.wrapWorking(
				() => {
					m.app.checkout.shippingAddress(toStripeAddress(address));
					return m.app.checkout.startCheckoutAPI();
				}
			)
		;
		return <div>
			<h2><label class='w5'>Shipping address:</label></h2>
			<Address refs={address} />
			<div class=''>
				<label><input type='checkbox' fn={data(m.app.checkout.localPickup)} /> Instead of shipping to this address, I'd like to schedule a local pickup (subject to the disgression of Sunburst Ridge Farm). Entering a valid address above is still required.</label>
			</div>
			<div class='mt-em center'>
				<StartOver {...m} />
				<button class='m1 w2' onClick={next}>Next</button>
			</div>
		</div>;
	},
	Address = ({refs}: {refs: AddressInputs}) => (
		<div>
			<input ref={refs.name} placeholder='Name' class='w5 mt-em' type='text' />
			<input ref={refs.line1} placeholder='Address line 1' class='w5 mt-em' type='text' />
			<input ref={refs.line2} placeholder='Line 2' class='w5 mt-em' type='text' />
			<input ref={refs.city} placeholder='City' class='city w3 or-w5 mt-em' type='text' />
			<input ref={refs.state} placeholder='State' class='state w1 or-w1-2 mt-em' type='text' />
			<input ref={refs.zip} placeholder='ZIP' class='zip w1 or-w1-2 mt-em' type='text' />
		</div>
	),
	Email = (m: CheckoutModel) => {
		if(m.app.isLoggedIn())
			return <div>
				<div class='w5'>We will use the email associated with your account ({m.app.user().email}) to send you information about your order.</div>
				<div class='center'>
					<button class='w2 mute' onClick={()=>m.app.logout()}>Logout</button>
					<button class='m1 w2' onClick={m.wrapWorking(()=>m.app.checkout.preCheckoutAPI())}>Accept</button>
				</div>
			</div>;
		else return (() => {
			const
				askEmail = S.value(false),
				emailInput = null;
			return <div>
				<h3 class='mb'>We'll need an email address for your order:</h3>
				<Login fire={m.app.fire} />
				<hr class='mt' />
				{
					askEmail() ?
					<div class='mt center'>
						<input class='w3' type='email' placeholder='Email' ref={emailInput} required autocomplete='email' />
						<button class='w2' onClick={()=>{m.app.checkout.preCheckoutAPI(emailInput.value)}}>Next</button>
					</div>
					:
					<div class='center mt'>
						<div><a class='g w5' onClick={()=>{askEmail(true);}}>Continue as guest</a></div>
						<div><small class='mt'>(we still require a valid email address)</small></div>
					</div>
				}
			</div>;
		}) as any as JSX.Element
	},
	ShippingOption = (m: CheckoutModel) => {
		const next = m.wrapWorking(() => m.app.checkout.finalizeCheckoutAPI());
		return <div>
			<h2><label>Shipping options:</label></h2>
			{
				m.app.checkout.shippingOptions().map(function(opt, i) {
					return <div class='pad'>
						<label>
							<input type='radio' checked={m.app.checkout.shippingChoice()===opt.id} onChange={
								e => {
									if(e.currentTarget.checked) m.app.checkout.shippingChoice(opt.id);
								}
							} />
							{opt.description} - {toMoneyString(opt.amount)}
						</label>
					</div>;
				})
			}
			<br />
			<h4>Note: Order processing time is 3-5 days.</h4>
			<div class='mt-em center'>
				<StartOver {...m} />
				<button class='m1 w2' onClick={next}>Next</button>
			</div>
		</div>;
	},
	StartOver = (m: CheckoutModel) => <button class='w2 mute' onClick={() => m.app.checkout.startOver()}>Start over</button>,
	Items = (m: CheckoutModel) => { // change to persistent and floating right?
		return <ul>
			{m.app.checkout.items().map(
				i => i.Description && i.Amount ? <li>
					<div class='g w4'>{i.Quantity > 1 ? i.Quantity + "\u00D7\xa0 " : null}{i.Description} ...</div>
					<div class='g w1 right'>{toMoneyString(i.Amount)}</div>
				</li> : null
			)}
			<li>
				<div class='g w4'><b>Total</b></div>
				<div class='g w1 right'><b>{toMoneyString(m.app.checkout.amount())}</b></div>
			</li>
		</ul>;
	},
	Billing = (m: CheckoutModel) => {
		const
			useSavedPayment = S.value(S.sample(m.app.checkout.savedPayment)!==null),
			savedPayment = S.sample(m.app.checkout.savedPayment) !== null ? S.sample(m.app.checkout.savedPayment) : null,
			savePayment = S.value(false),
			addressRefs: AddressInputs = {},
			next = m.wrapWorking(() => m.app.checkout.confirmAPI(useSavedPayment(), savePayment(), toStripeBillingAddress(addressRefs)))
			// sameAsShippingAddress = S.value(true)
		;
		return <div>
			<h2 class='mb'><label>Order summary:</label></h2>
			<Items {...m} />
			<h2 class='mt mb'><label>Billing options:</label></h2>
			{
				m.app.checkout.savedPayment() !== null
				? <label class='g w5 pad'><input type='checkbox' fn={data(useSavedPayment)} /> Use {savedPayment.brand} - {savedPayment.last4}</label>
				: <div>
					<div fn={m.app.checkout.mountCard}></div>
					{/* <label class='g w5 pad'><input type='checkbox' fn={data(sameAsShippingAddress)} /> Billing address is the same as shipping address.</label> */}
					<Address refs={addressRefs} />
					{/* { m.app.isLoggedIn()
					? <label class='g w5 pad'><input type='checkbox' fn={data(savePayment)} /> Save shipping and payment information for future orders</label>
					: null
					} */}
				</div>
			}
			<div class='mt-em center'>
				<StartOver {...m} />
				<button class='m1 w2' onClick={next}>Confirm payment</button>
			</div>
		</div>;
	},
	Completed = (m: CheckoutModel) => {
		return <div>
			<h2><label>Your order has been placed!</label></h2>
			<Items {...m} />
			<p>
				{m.app.checkout.localPickup()
				? "You've opted for a local pickup. You'll receive an email with contact instructions to arrange this. Thanks!"
				: 'You will receive an email with shipment tracking information.'}
			</p>
			<div class='mt-em center'>
				<button class='w2 mute' onClick={()=>{
					m.app.checkout.startOver();
					m.app.location('/#/store');
				}}>Continue</button>
			</div>
		</div>;
	}
;

interface AddressInputs {
	name?: HTMLInputElement,
	line1?: HTMLInputElement,
	line2?: HTMLInputElement,
	city?: HTMLInputElement,
	state?: HTMLInputElement,
	zip?: HTMLInputElement,
}

function toMoneyString(n: number): string {
	return '$'+(n/100).toFixed(2)
}

function toStripeAddress(a: AddressInputs): StripeAddress {
	return {
		name: a.name.value,
		address: {
			line1: a.line1.value,
			line2: a.line2.value,
			city: a.city.value,
			state: a.state.value,
			postal_code: a.zip.value,
			country: 'US',
		},
	}
}

function toStripeBillingAddress(a: AddressInputs) {
	return {
		name: a.name.value,
		address_line1: a.line1.value,
		address_line2: a.line2.value,
		address_city: a.city.value,
		address_state: a.state.value,
		address_zip: a.zip.value,
		address_country: 'US',
	}
}