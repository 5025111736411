import { M } from '../util';
import firebase from 'firebase/app';
import 'firebase/firestore';
import firestore = firebase.firestore;

export interface User {
	token: string;
	id: string;
	email: string;
	name: string;
	// receivesNewsletter: boolean;
}

export const makeUser = async (u: firebase.User) => ({
	token: await u.getIdToken(),
	id: u.uid,
	email: u.email,
	name: u.displayName
});

export interface IDd { id: string }
export interface Named { Name: string }
export interface Updatable { Updated: firestore.Timestamp }
export function touch(o: Updatable) {
	o.Updated = firestore.Timestamp.now();
}

interface _Product extends IDd, Named {
	Description: string;
	Ingredients: string;
	Price: number;
	Shipping: {
		Weight: number;
		Height: number;
		Length: number;
		Width: number;
	};
	Stock: number;
	Sold: number;
	Discontinued: boolean;
}
export interface DBProduct extends _Product {
	Category: firestore.DocumentReference;
	Tags: firestore.DocumentReference[];
}
export interface Product extends _Product {
	Category: string;
	Tags: string[];
}
export function toProduct(prod: DBProduct): Product {
	const p = prod as any as Product;
	p.Category = prod.Category.id;
	p.Tags = prod.Tags.map(t=>t.id);
	return p;
}

export interface Category extends IDd, Named {
	Description: string;
	Sold: number;
}

export interface Tag extends IDd, Named {
	Description: string;
	Sold: number;
}

export interface Event extends IDd, Named {
	Description: string;
	Date: string;
	AddressLn1: string;
	AddressLn2: string;
}

export interface BrickAndMortar extends IDd, Named {
	Description: string;
	AddressLn1: string;
	AddressLn2: string;
	Ordering: number;
}

export interface StoreState {
	id: 'State';
	Enabled: boolean;
	Message: string;
	AboutTextHeader: string;
	AboutText: string;
}

// export interface VisibleOrder extends IDd {}

export function toMap<T extends IDd>(ts: T[]): M<T> {
	const m = ts.reduce((m, t) => {
		m[t.id] = t;
		return m;
	}, {});
	return m;
}

export function matchAny<T>(ts: T[], vs: T[]): boolean {
	for (let t of ts) if (vs.includes(t)) return true;
	return false;
}

export function price(p: number): string {
	return '$' + (p/100.0).toFixed(2);
}