import S from 's-js';
import * as Surplus from "surplus";
import { App } from "../../app/main/app";
import { Logo } from '../Logo';
import { PromiseLoader } from '../Loader';

export { Page }

export const enum MenuSection {
	About,
	Store,
	Events,
	BrickAndMortar,
	Cart,
	LogIn,
};
export const enum CheckoutMenuSection {
	Cart = 100,
	LogIn,
	Shipping,
	Billing,
	Confirmation,
	Completion,
};
export function checkingOut(s: MenuSection | CheckoutMenuSection): s is CheckoutMenuSection {
	return s >= 100;
}
function sectionDisabled(current: CheckoutMenuSection, inQuestion: CheckoutMenuSection): boolean {
	if(current === CheckoutMenuSection.Completion) return true;
	return inQuestion >= current;
}

const
	TitlePrefix = 'Sunburst Ridge Farm',
    /**
     * Page element, for wrapping content and displaying the standard conduit page with header and footer
     */
	Page = (
		{ app, section, title, children } :
		{ app: App, section: MenuSection | CheckoutMenuSection, title?: string | (() => string), children: JSX.Children }
	) => {
		PageTitle(title);
		return <div>
			<Header section={section} app={app} />
			<PromiseLoader p={
				app.client.storeState()
					.then(s => {
						if (pageYOffset > 265) scrollTo(0, 265);
						return s;
					})
					.then(s => 
					!s.Enabled
					? <div class='error mb'>{s.Message}</div>
					: s.Message && section <= 100
					? <div class='info mb'>{s.Message}</div>
					: null)
			} />
			{children as JSX.Element}
			<Footer />
		</div>;
	},
    /**
     * Set the page's title to the given string, and restore it when done
     */
	PageTitle = (title?: string | (() => string)) => {
		const el = document.head.querySelector('title')!,
			prevTitle = el.textContent;
		return S(() => {
			const _title = typeof title === 'function' ? title() : title;
			if (!_title) return;
			el.textContent = TitlePrefix + ' | ' + _title;
			S.cleanup(() => el.textContent = prevTitle);
		});
	},
	Header = ({ section, app: { user, isLoggedIn, cart } }: { section: MenuSection | CheckoutMenuSection, app: App }) => {
		const showSubNav = S.value(false),
				toggleSubNav = ()=>showSubNav(!showSubNav());
		return [
			<ul class='social'>
				<li><a href='https://www.facebook.com/SunburstRidgeFarm'><i class='fab fa-facebook fa-lg'></i></a></li>
				<li><a href='https://www.instagram.com/sunburstfarm'><i class='fab fa-instagram fa-lg'></i></a></li>
			</ul>,
			<nav>
				<ul class='g w3'>
					<SectionLink class='brand' href="#">
						<Logo width={0} />
					</SectionLink>
				</ul>
				{
					!checkingOut(section) ?
					[
						<ul class='g w1 menu'>
							<SectionLink adorned href="#/store" active={section === MenuSection.Store}>Store</SectionLink>
							<SectionLink adorned href="#/events" active={section === MenuSection.Events}>Events</SectionLink>
							<SectionLink adorned href="#/brick-and-mortar" active={section === MenuSection.BrickAndMortar}>Brick-&-Mortar</SectionLink>
							<SectionLink adorned href="#/" active={section === MenuSection.About}>About</SectionLink>
						</ul>,
						<ul class='g w1 menu'>
							{cart.count()?<SectionLink adorned href="#/cart" active={section === MenuSection.Cart}>Cart ({cart.count()})</SectionLink>:null}
						</ul>
					]
					:
					<ul class='g w1 menu'>
						<SectionLink href="#/cart" class='mute'
							active={section === CheckoutMenuSection.Cart}
						>Cart</SectionLink>
						{!isLoggedIn()?<SectionLink active={section === CheckoutMenuSection.LogIn} disabled>1. Email</SectionLink>
							: <SectionLink active={section === CheckoutMenuSection.LogIn} disabled>1. Account</SectionLink>}
						<SectionLink
							active={section === CheckoutMenuSection.Shipping}
							disabled
						>2. Shipping</SectionLink>
						<SectionLink
							active={section === CheckoutMenuSection.Billing}
							disabled
						>3. Billing</SectionLink>
						<SectionLink
							active={section === CheckoutMenuSection.Confirmation}
							disabled
						>4. Confirmation</SectionLink>
						{
							section === CheckoutMenuSection.Completion
							? <SectionLink active disabled>Summary</SectionLink>
							: null
						}
					</ul>
				}
			</nav>
		] as any as JSX.Element;
	},
	SectionLink = (
		{ class:c, active, disabled, adorned, href, click, children }:
		{ class?: string, active?: boolean, disabled?: boolean, adorned?: boolean, href?: string, click?: ()=>void, children: JSX.Children }
	) => (
		<li class={c||''}>
			<a class={(active ? "active" : "") + (disabled?' disabled':'') + (adorned?' adorn':'')}
				{...href && !disabled ? {href:href} : {}}
				{...click && !disabled ? {onClick:click} : {}}
			>
				{children}
			</a>
		</li>
	),
	Footer = () => (
		<footer>
			<a href="#">
				<Logo width={200} blackAndWhite />
			</a>
			<div>
				© 2020 Sunburst Ridge Farm<br/>
				Cincinnati, OH<br />
				<a href='mailto:support@sunburstridgefarm.com'>Send us an email</a><br /><br />
				Legal: <a onClick={()=>window.open('/#/legal/tos','_blank')}>Terms</a> | <a onClick={()=>window.open('/#/legal/privacy','_blank')}>Privacy</a><br /><br />
				<em>branding and website created by <a href='mailto:jacob.cole.jsc+srf@gmail.com'>Jacob Cole</a></em>
			</div>
		</footer>
	);