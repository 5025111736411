import S, {DataSignal} from 's-js';
import * as Surplus from "surplus";
import SArray from 's-array';

import { App } from "../../app/main/app";

import { Page, MenuSection, CheckoutMenuSection } from "./Page";
import { Event, BrickAndMortar } from '../../app/model';

export { BrickAndMortarRoute };

type BrickAndMortarModel = ReturnType<typeof BrickAndMortarModel>;
// type BrickAndMortarRouteOpts = {};
const
	BrickAndMortarRoute = async (app: App) => {
		const model = BrickAndMortarModel(app);
		return () => <BrickAndMortarPage {...model} />
	},
	BrickAndMortarModel = ( app: App) => {
		const
			{ client } = app,
			items = SArray<BrickAndMortar>([]);
		app.client.brickAndMortar().then(items);
		return { items, app };
	},
	BrickAndMortarPage = (model: BrickAndMortarModel) => (
		<Page app={model.app} title="Brick-&-Mortar" section={MenuSection.BrickAndMortar}>
			<BrickAndMortar {...model} />
		</Page>
	),
	BrickAndMortar = ({ app: { fire, location }, ...m }: BrickAndMortarModel) => (
		<div>
			{m.items().length === 0 ? <p class='pad center'>Loading ...</p> : null}
			{(m.items.mapSequentially(bam => <div class='mb'>
				<h2>{bam.Name}</h2>
				<p class='mt-em point-at'><a href={'https://www.google.com/maps/place/' + encodeURI(bam.AddressLn1 + ', ' + bam.AddressLn2)} target='_blank'>
					{bam.AddressLn1}<br />
					{bam.AddressLn2}
				</a></p>
				<div class='g m1 w3 mt-em'>
					<p>{bam.Description}</p>
				</div>
			</div>))()}
			<div class='g m1 w3 mt'>
				{/* <div class='center mt'>&#10051;</div> */}
				<div class='center mt color'>&#10086;</div>
				<div class='center mt'>
					<div class='center mt'>
						<button class='mute' onClick={() => location('/#/store')}>Browse the online store</button>
					</div>
				</div>
			</div>
		</div>
	);