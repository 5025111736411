import S, {DataSignal} from 's-js';
import * as Surplus from "surplus";
import SArray from 's-array';

import { App } from "../../app/main/app";

import { Page, MenuSection, CheckoutMenuSection } from "./Page";
import { Event } from '../../app/model';

export { EventsRoute };

type EventsModel = ReturnType<typeof EventsModel>;
// type EventsRouteOpts = {};
const
	EventsRoute = async (app: App) => {
		const model = EventsModel(app);
		return () => <EventsPage {...model} />
	},
	EventsModel = ( app: App) => {
		const items = SArray<Event>([]);
		app.client.events().then(items);
		return { items, app };
	},
	EventsPage = (model: EventsModel) => (
		<Page app={model.app} title="Events" section={MenuSection.Events}>
			<Events {...model} />
		</Page>
	),
	Events = ({ app: { fire, location }, ...m }: EventsModel) => (
		<div>
			{m.items().length === 0 ? <p class='pad center'>There are no upcoming events. Stop back soon!</p> : null}
			{(m.items.mapSequentially(event => <div>
				<h2>{event.Name}</h2>
				<h3 class='mt-em'>{event.Date}</h3>
				<p class='mt-em point-at'><a href={'https://www.google.com/maps/place/' + encodeURI(event.AddressLn1 + ', ' + event.AddressLn2)} target='_blank'>
					{event.AddressLn1}<br />
					{event.AddressLn2}
				</a></p>
				<div class='g m1 w3 mt-em'>
					<p>{event.Description}</p>
				</div>
			</div>))()}
			<div class='g m1 w3 mt'>
				{/* <div class='center mt'>&#10051;</div> */}
				<div class='center mt color'>&#10086;</div>
				<div class='center mt'>
					<div class='center mt'>
						<button class='mute' onClick={() => location('/#/store')}>Browse the online store</button>
					</div>
				</div>
			</div>
		</div>
	);