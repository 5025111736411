import S from 's-js';
import * as Surplus from "surplus";
import SArray from 's-array';

import firebase from "firebase/app";


import { App } from "../../app/main/app";
import { Product, Category } from "../../app/model";

import { Page, MenuSection } from "./Page";
import { Loader } from '../Loader';
import { promiseSArray, promiseSData } from '../../util';
import { Thumbnail } from '../Thumbnail';

export { StoreRoute };

type StoreModel = ReturnType<typeof StoreModel>;
type StoreRouteOpts = { category?: string, tags?: string[] };
const
	StoreRoute = async (app: App, opts?: StoreRouteOpts) => {
		const model = StoreModel(app, opts||{});
		return () => <StorePage {...model} />
	},
	StoreModel = (app: App, { category: _initCat, tags: _initTags }: StoreRouteOpts) => {
		const
			categories = promiseSArray<Category>(app.client.categories().then(Object.values)),
			category = S.value<string>(_initCat || null),
			tags = promiseSData(app.client.tags(), {}),
			tagsSelected = SArray(_initTags ? _initTags.filter(t=>!!t) : []),
			products = promiseSData(app.client.products()),
			initialized = S(()=> !!categories().length && !!Object.keys(tags()).length && !!products());
		S(()=>{
			app.client.products(category(), tagsSelected()).then(ps=>products(ps))
		});
		S(()=>{
			if(!!category()) app.location(`#/store/category/${category()}`)
			else if(tagsSelected().length) app.location(`#/store/tags/${tagsSelected().join('+')}`)
			else app.location('#/store');
		});
		return { app, categories, category, tags, tagsSelected, products, initialized };
	},
	StorePage = (model: StoreModel) => (
		<Page app={model.app} title="Store" section={MenuSection.Store}>
			<Loader initialized={model.initialized}>{(() => <Store {...model} />) as any as JSX.Element}</Loader>
		</Page>
	),
	Store = ({ app, initialized, ...m }: StoreModel) => {
		const tags = Object.keys(m.tags()).map(function (id) {
			if (m.tagsSelected().includes(id)) { // todo first or second?
				return <button class={'tag selected'} onClick={() =>
					m.tagsSelected.remove(id)
				}>{m.tags()[id].Name}</button>;
			}
			const products = Object.values(m.products());
			for (let p of products) { // todo first or second?
				if (p.Tags.includes(id)) {
					const tag = m.tags()[id];
					return <button class={'tag ' + (m.tagsSelected().includes(tag.id) ? 'selected' : 'unselected')} onClick={() =>
						m.tagsSelected().includes(tag.id) ? m.tagsSelected.remove(tag.id) : m.tagsSelected.push(tag.id)
					}>{tag.Name}</button>;
				}
			}
			return null;
		}).filter(val => val !== null);
		return <div>
			<h1 class='mb'><div class='g w2'>Store</div><div class='g w1 center'>&#8728; &nbsp; &#8728; &nbsp; &#8728;</div></h1>
			<div class='tags'>
				Category: &nbsp;
				{m.categories().map(function(ctg) {
					return <button class={'tag '+(m.category() === ctg.id?'selected':'unselected')} onClick={() =>
						m.category() === ctg.id ? m.category(null) : m.category(ctg.id)
					}>{ctg.Name}</button>;
				})}
			</div>
			{tags.length ? <div class='tags'>Filters: &nbsp; {tags}</div> : null}
			<div class='mt vtop'>
				{
					!Object.keys(m.products()).length
					? <div class='center'>Nothing matches your criteria.<br /><button onClick={() => { m.category(null); m.tagsSelected([]) }}>Clear criteria</button></div>
					: Object.values(m.products()).map(p=>ProductDisplay(app.fire, p))
				}
			</div>
		</div>;
	},
	ProductDisplay = (fire: firebase.app.App, p: Product) => (
		<a class='g w1 or-w1-2 center pad adorn2' href={`#/store/${p.id}`}>
			<Thumbnail fire={fire} id={p.id} />
			<div class='pad'>{p.Name}</div>
		</a>
	)
	;