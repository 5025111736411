import S from 's-js';
import * as Surplus from 'surplus';

import firebase from "firebase/app";
import firebaseui = require('firebaseui');

import { Lazy } from '../util';



let aui: Lazy<firebaseui.auth.AuthUI>;
let ref: HTMLDivElement;
export const Login = ({ fire }: { fire: firebase.app.App }) => {
	if (!aui) aui = new Lazy(() => new firebaseui.auth.AuthUI(fire.auth()));
	
	let el = <div id='auth' ref={ref} />;
	aui.get().then(aui=> {
		aui.start(ref, {
			signInFlow: 'popup',
			callbacks: {signInSuccessWithAuthResult: () => false},
			signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
			tosUrl: ()=>{ window.open('/#/legal/tos', '_blank'); },
			privacyPolicyUrl: ()=>{ window.open('/#/legal/privacy', '_blank'); },
		});
	});
	return el;
};