import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

import * as CFG from "../config";


export const fire = (admin: boolean) => {
	const fire = firebase.initializeApp(admin ? CFG.AdminCFG.fire : CFG.PublicCFG.fire);
	return fire;
};