import S from 's-js';
import firebase from 'firebase/app';
import { User } from '../model';
import { AuthMonitor } from '../authMonitor';
import { Cart } from './cart';
import { StripeAddress } from './checkout';
import { PublicCFG } from '../../config';




export type FunctionsClient = ReturnType<typeof getFunctionsClient>;

export const getFunctionsClient = (
	fire: firebase.app.App,
	user: () => User,
	auth: AuthMonitor,
	cart: Cart
) => {
	return {
		preCheckout: async () => {
			if(!S.sample(user)) return null; // || !S.sample(user).email) return;
			return call("PreCheckout", {Token: S.sample(user).token})
				.catch(fatal)
				.then(r => r as {
					StripeShipping?: stripe.ShippingDetails,
					CardLast4?: string,
					CardBrand?: string,
				});
		},
		startCheckout: async (shipping: StripeAddress, localPickup: boolean, email: string) =>
			call("StartCheckout", {
				Token: S.sample(user) ? S.sample(user).token : "",
				Email: email,
				Shipping: shipping,
				LocalPickup: localPickup,
				Cart: cart.toMapSample(),
			})
			.catch(fatal)
			.then(r => r as ShippingOptions | OrderResponse)
		,
		finalizeCheckout: async (email: string, shippingOption?: string) =>
			call("FinalizeCheckout", {
				Token: S.sample(user) ? S.sample(user).token : "",
				Email: email,
				ShippingOption: shippingOption,
			})
			.catch(fatal)
			.then(r => r as OrderResponse)
		,
		confirmCheckout: async (email: string, useSavedPayment: boolean, payment?: string, savePayment?: boolean) =>
			call("ConfirmCheckout", {
				Token: S.sample(user) ? S.sample(user).token : "",
				Email: email,
				UseSavedPayment: useSavedPayment,
				Payment: payment,
				SaveInfo: savePayment,
			})
			.catch(fatal)
			.then(r => r as OrderResponse)
		,
	};

	async function call(route: string, data?: any) {
		return fetch(PublicCFG.fire.functionsURL + route, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			// referrer: 'no-referrer', // no-referrer, *client
			body: JSON.stringify(data),
		})
		.then((resp) => {
			if(resp.status < 200 || resp.status >= 300)
				throw resp;
			if(resp.status === 204) return null;
			return resp.json();
		})
	}
	
	function fatal(r: any): firebase.functions.HttpsCallableResult {
		console.error(r);
		throw {
			FatalError: "An unexpected error occurred. Please try again."
		};
	}
};

export function isFatal(r): r is {FatalError: string} {
	return r.hasOwnProperty('FatalError');
}

export interface ShippingOptions {
	ShippingSelected: string,
	ShippingOptions: ShippingOption[],
}
export interface ShippingOption {
	amount: number,
	id: string,
	currency: string,
	// delivery_estimate: 
	description: string,
}

export interface OrderResponse {
	Items: OrderResponseItem[],
	Amount: number,
	Error?: {
		Type: string,
		Message: string,
	},
	Finalized: boolean,
	Paid: boolean,
}
export interface OrderResponseItem {
	Description: string,
	Quantity: number,
	Amount: number,
}