import S, {DataSignal} from 's-js';
import * as Surplus from "surplus";
import data from 'surplus-mixin-data';
import SArray from 's-array';

import { App } from "../../app/main/app";

import { Page, MenuSection, CheckoutMenuSection } from "./Page";
import { Product, price } from '../../app/model';
import { Thumbnail } from '../Thumbnail';
import { CartQty } from './CartQty';

export { CartRoute };

type CartModel = ReturnType<typeof CartModel>;
type CartRouteOpts = { category?: string, tags?: string[] };
const
	CartRoute = async (app: App) => {
		const model = CartModel(app);
		return () => <CartPage {...model} />
	},
	CartModel = ( app: App) => {
		interface ItemSummary {
			Category: string;
			Product: string;
			Stock: number;
			Price: number;
		};
		const
			{ cart: { cart, count, qty, remove }, client } = app,
			items = S.data({} as Record<string, ItemSummary>);
		S(() => Object.keys(cart()).forEach(id => {
			if(!items()[id]) client.product(id)
				.then(p => ({ Category: p.Category, Product: p.Name, Price: p.Price, Stock: p.Stock }))
				.then(p => {
					client.categories().then(categories => {
						p.Category = categories[p.Category].Name;
						const temp = items();
						temp[id] = p;
						items(temp);
					});
				});
		}));
		return { items, cart, count, remove, app };
	},
	CartPage = (model: CartModel) => (
		<Page app={model.app} title="Cart" section={MenuSection.Cart}>
			<Cart {...model} />
		</Page>
	),
	Cart = ({ app: { cart, fire, location, client }, ...m }: CartModel) => (
		<div>
			{Object.keys(m.cart()).map(id => {
				const p = m.items()[id];

				return !p ? <div>Loading</div> :
					<div class='vcenter center'>
						<div class='g w1 center'><Thumbnail fire={fire} id={id} /></div>
						<h3 class='g w2 pad center'>{p.Product} <small>({p.Category})</small></h3>
						<div class='g w1 center'>{price(p.Price)} each</div>
						<div class='g w1 center'>
							<CartQty cart={cart} location={location} id={id} stock={p.Stock} />
						</div>
					</div>;
			})}
			<div class='center'>
				{
					cart.count()
					? <button onClick={() => location('/#/check-out')}>Check Out</button>
					: <div class='pad'>
							<div>Your cart is empty!</div>
							<button onClick={() => location('/#/store')}>Go to store</button>
						</div>
				}
			</div>
		</div>
	);