import S, {DataSignal} from 's-js';
import SArray, { SDataArray } from 's-array';

export type TypeOf = "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";

export class SPromise<T> extends Promise<T>{
	constructor(data: DataSignal<T>, timeoutSeconds?: number, completed?: (t: T) => boolean) {
		super((resolve, reject) => {
			var timeout: NodeJS.Timeout;
			if(timeoutSeconds) timeout = setTimeout(() => { reject(`SPromise rejected: timeout of ${timeoutSeconds} reached`) }, timeoutSeconds * 1000);
			S.on(data, () => {
				if(!completed || completed(data())) {
					if(timeoutSeconds) clearTimeout(timeout);
					resolve(data());
				}
			});
		});
	}
}

export type M<T> = { [key: string]: T };


export const cache = function<T>(expiryMinutes: number, get: (...args) => Promise<T>, ...args) {
	let it: T|undefined;
	return async function() {
		if(it !== undefined) return it;
		it = await get(...args);
		setTimeout(() => it = undefined, expiryMinutes * 60 * 1000);
		return it;
	};
};

export class Cache<T> {
	private it?: T;
	constructor(private expiryMinutes: number, private getter: () => Promise<T>) {}
	async get() {
		if(this.it !== undefined) return this.it;
		this.it = await this.getter();
		setTimeout(() => this.it = undefined, this.expiryMinutes * 60 * 1000);
		return this.it;
	}
	expire() { this.it = undefined; }
	cached() { return this.it !== undefined; }
}

export function cachedOr<T>(cache: Cache<M<T>>, id: string, or: () => Promise<T>): Promise<T> {
	if (!cache.cached()) return or();
	const t = cache.get()[id];
	return t === undefined ? or() : t;
}



export const lazy = function<T>(init: (...args) => T|Promise<T>, ...args) {
	let it: T | undefined;
	return async function() {
		if (it !== undefined) return it;
		it = await init(...args);
		return it;
	};
};

export class Lazy<T> {
	private it?: T;
	constructor(private init: () => T|Promise<T>) { }
	async get() {
		if (this.it == undefined) this.it = await this.init();
		return this.it;
	}
}



export function promiseSData<T>(p: Promise<T>, seed?: T): DataSignal<T | undefined> {
	const val = S.data(seed || undefined);
	p.then(t => val(t));
	return val;
}
export function promiseSArray<T>(p: Promise<T[]>, seed?: T[]): SDataArray<T> {
	const val = SArray(seed || []);
	p.then(t => val(t));
	return val;
}
