import S from 's-js';
import * as Surplus from "surplus";

import { App } from "../../app/main/app";

import { Page, MenuSection } from "./Page";
import { PromiseLoader } from '../Loader';

export { HomeRoute };

import parabenFree from '../../img/paraben-free.svg';
import sulfateFree from '../../img/sulfate-free.svg';
import gmoFree from '../../img/gmo-free.svg';

const
	HomeRoute = async (app: App) => {
		return () => <HomePage {...app} />
	},
	HomePage = (app: App) => (
		<Page app={app} title="About" section={MenuSection.About}>
			<Home {...app} />
		</Page>
	),
	Home = (app: App) => (
		<div id="home">
			<div class='round hero'></div>
			<br />
			<PromiseLoader p={app.client.storeState().then(s=> [
				<h1 class='mt mb'>{s.AboutTextHeader}</h1>,
				<p>{s.AboutText}</p>
			])} />
			<div class='center mt'>
				<img src={parabenFree} width='100' class='pad' />
				<img src={sulfateFree} width='100' class='pad' />
				<img src={gmoFree} width='100' class='pad' />
			</div>
			<div class='g m1 w3 mt'>
				{/* <div class='center mt'>&#10051;</div> */}
				<div class='center mt color'>&#10086;</div>
				<div class='center mt'>
					<div class='center mt'>
						<button class='mute' onClick={() => app.location('/#/store')}>Browse the online store</button>
					</div>
				</div>
			</div>
		</div>
	);