import S from "s-js";

import {fire as firebase} from '../firebase';
import {Client, isAuthFailure} from "./client";
import {User, makeUser} from '../model';
import {HashSignal} from "../hashSignal";
import { getCart} from "./cart";
import { AuthMonitor } from "../authMonitor";
import { PublicCFG } from "../../config";
import { getCheckout } from "./checkout";
import { getFunctionsClient } from "./functionsClient";

/**
 * App - core state and services
 */
export type App = ReturnType<typeof App>;
export const App = () => {
	const
		user			= S.data(null as User | null),
		isLoggedIn		= S.on(user, ()=>(user() !== null)),
		// logout 			= async () => {
		// 	fire.auth().signOut();
		// 	//user(null);
		// },
		fire			= firebase(false),
		location		= HashSignal(),
		auth			= AuthMonitor<User>(user, makeUser, isLoggedIn, isAuthFailure),
		logout			= auth.logOut,
		client			= Client(fire, user, auth),
		cart			= getCart(client),
		fns				= getFunctionsClient(fire, user, auth, cart),
		checkout		= getCheckout(fns, PublicCFG.stripe, cart, user)
		;

	return { user, isLoggedIn, location, logout, client, fire, cart, checkout, auth, init: init() };

	async function init(): Promise<void> {}
};